import paraphilia from "./Band";

const { name, allReleases, social_links, website } = paraphilia;
const { name: albumName, year } = allReleases[0];

const title: string = `${name} | Official Site`.toUpperCase();
const description: string =
  `${name} - ${albumName} (${year}) - available now!`.toUpperCase();

type Twitter = {
  description: string;
  card: string;
  site: string;
  title: string;
  img: string;
  creator: string;
};

interface SiteData {
  title: string;
  description: string;
  char_set: string;
  type: string;
  name: string;
  photo: string;
  link_href: string;
  local: string;
  og_description: string;
  og_img: string;
  og_img_width: string;
  og_img_height: string;
  og_img_alt: string;
  site_name: string;
  twitter: Twitter;
  og_img_secure?: string;
}

const siteData: SiteData = {
  title,
  description,
  char_set: "utf-8",
  type: "website",
  name: `${name} | Official Site`,
  photo: "",
  link_href: `${website}`,
  local: "en_US",
  og_description: "",
  og_img: "",
  og_img_secure: "",
  og_img_width: "",
  og_img_height: "",
  og_img_alt: `official-${name}-logo`,
  site_name: `${name}`,
  twitter: {
    card: "summary",
    site: `${social_links.twitter.url}`,
    description: "",
    title: "",
    img: "",
    creator: "",
  },
};

// Data/Social Media Updates
siteData.og_description = siteData.description;
siteData.og_img_secure = siteData.og_img;

// Twitter data updates
siteData.twitter.description = siteData.description;
siteData.twitter.title = siteData.title;
siteData.twitter.img = siteData.og_img;
siteData.twitter.creator = siteData.twitter.site;

export default siteData;
