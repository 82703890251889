import React from "react";

type Props = {
  logo: string;
};

const Header = ({ logo }: Props) => {
  return (
    <header>
      <a href="/">
        <img src={logo} alt="Paraphilia Logo" />
      </a>
    </header>
  );
};

export default Header;
