import React from "react";

type Tab = {
  name: string;
  show: boolean;
  download: boolean;
  downloadLink: string;
};

const GuitarTab = () => {
  const tabs: Tab[] = [
    {
      name: "Coruscation (Guitar & Bass)",
      show: true,
      download: true,
      downloadLink:
        "https://drive.google.com/open?id=1yRekndWwgYJlFmdULE_TR6wjcDCjNF7l&authuser=arkhumlead02%40gmail.com&usp=drive_fs",
    },
    {
      name: "Supernal Rebuke (Guitar & Bass)",
      show: false,
      download: false,
      downloadLink: "",
    },
  ];

  const downloadBtn = (text: string, link: string) => {
    return (
      <a href={link}>
        <button>{text}</button>
      </a>
    );
  };

  const promptText = (download: boolean) =>
    download ? `(CLICK to Download)` : `(COMING SOON)`;

  const showTabList = (arr: Tab[]) => {
    return arr.map((tab) => {
      const { name, download, downloadLink } = tab;
      const prompt = promptText(download);

      return (
        <li className="tab-list--button" style={{ display: "flex" }}>
          {name} - {downloadBtn(prompt, downloadLink)}
        </li>
      );
    });
  };

  return (
    <div className="guitar-tab-container">
      <div>
        <h2>Guitar Tab Preview Here</h2>
      </div>
      <ul>{showTabList(tabs)}</ul>
    </div>
  );
};

export default GuitarTab;
