import React from 'react';

import NavigationItems from './NavigationItems/NavigationItems.jsx'

const Navigation = () => {
  return (
    <nav className="desktop--nav">
      <NavigationItems />
    </nav>
  )
}

export default Navigation;
