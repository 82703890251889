import React from "react";
import paraphilia from "../../data/Band";

const { navItems } = paraphilia;

const NavigationItems = () => {
  const navItemGeneration = navItems.map((item) => {
    if (item.active === true && item.desktop === true) {
      return (
        <li key={item.key}>
          <a
            href={item.link}
            target={item.link[0] === "/" ? "_self" : "_blank"}
            rel="noopener noreferrer"
            title={item.name}
          >
            {item.name}
          </a>
        </li>
      );
    }
  });

  return <ul className="nav--list">{navItemGeneration}</ul>;
};

export default NavigationItems;
