import React from "react";
import { Route, Routes } from "react-router";

import GuitarTab from "../GuitarTab/GuitarTab";

type Props = {
  featuredVideoEmbed: string;
};

const Content = ({ featuredVideoEmbed }: Props) => {
  const featuredVideo = () => {
    return (
      <iframe
        width="400"
        height="200"
        src={`${featuredVideoEmbed}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  };

  return (
    <div className="content--main">
      <Routes>
        <Route path="/" element={featuredVideo()} />
        <Route path="/guitar-tabs" element={<GuitarTab />} />
      </Routes>
    </div>
  );
};

export default Content;
