import React from "react";

// Components
import Content from "../Content/Content";
import Navigation from "../Navigation/Navigation";
import MobileNav from "../MobileNav/MobileNav";
import BcPlayer from "../BcPlayer/BcPlayer";
import { Band, Release } from "../data/Band";

type Props = {
  band: Band;
};

const Main = ({ band }: Props) => {
  const { allReleases } = band;
  const latestRelease: Release = allReleases[0];
  const { featuredVideoEmbed } = latestRelease;

  return (
    <div className="main-background">
      <main>
        <MobileNav />
        <Content featuredVideoEmbed={featuredVideoEmbed} />
        <Navigation />
        <BcPlayer featuredRelease={latestRelease} />
      </main>
    </div>
  );
};

export default Main;
