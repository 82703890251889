import {
  faBandcamp,
  faSpotify,
  faFacebook,
  faInstagram,
  IconDefinition,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faCompactDisc } from "@fortawesome/free-solid-svg-icons";

import logo from "../../styles/images/Paraphilia_Yellow_Transparent.png";
import background from "../../styles/images/Paraphilia_FB Banner.jpg";
import tmodgf_background from "../../styles/images/TMoDGF-Website-Background.jpg";

type Social = {
  title: string;
  url: string;
};

type Member = {
  name: string;
  instrument: string;
  instagram: string;
};

type BandcampEmbed = {
  name: string;
  machine_name: string;
  id: string;
  tracks: string[];
};

export interface Release {
  name: string;
  bandcampUrl: string;
  storeUrl?: string;
  featuredVideoEmbed: string;
  backgroundImg: string;
  bandcampEmbed: BandcampEmbed;
  year: string;
}

type NavItem = {
  name: string;
  link: string;
  icon: IconDefinition;
  active: boolean;
  desktop: boolean;
  mobile: boolean;
  key: number;
};

export interface Band {
  name: string;
  bandcampUrl: string;
  logo: string;
  social_links: {
    instagram: Social;
    facebook: Social;
    twitter: Social;
  };
  photos?: string[];
  featuredRelease?: Release;
  allReleases: Release[];
  members: Member[];
  navItems: NavItem[];
  website: string;
}

const band: Band = {
  name: "Paraphilia",
  website: "https://paraphilia.band",
  bandcampUrl: "https://paraphilia.bandcamp.com/",
  social_links: {
    instagram: {
      title: "Instagram",
      url: "paraphilia.death",
    },
    facebook: {
      title: "Facebook",
      url: "paraphilia.band",
    },
    twitter: {
      title: "Twitter",
      url: "@paraphilia_band",
    },
  },
  allReleases: [
    // Always put the latest album first
    {
      name: "The Memory of Death Given Form",
      year: "2023",
      bandcampUrl:
        "https://paraphilia.bandcamp.com/album/the-memory-of-death-given-form",
      bandcampEmbed: {
        name: "The Memory of Death Given Form",
        machine_name: "the-memory-of-death-given-form",
        id: "752387265",
        tracks: ["618549591"],
      },
      backgroundImg: tmodgf_background,
      featuredVideoEmbed: "https://www.youtube.com/embed/hWEk55r2EgY",
    },
    {
      name: "Primordium of Sinister Butchery",
      year: "2021",
      bandcampUrl:
        "https://paraphilia.bandcamp.com/album/primordium-of-sinister-butchery",
      bandcampEmbed: {
        name: "Primordium of Sinister Butchery",
        machine_name: "primordium-of-sinister-butchery",
        id: "4220900892",
        tracks: ["1236276314", "616080703"],
      },
      backgroundImg: background,
      featuredVideoEmbed: "https://www.youtube.com/embed/qclzponZX98",
    },
  ],
  logo,
  photos: [""],
  members: [
    {
      name: "SP",
      instrument: "Guitar, Bass & Vocals",
      instagram: "stephenparkhum",
    },
    {
      name: "KP",
      instrument: "Vocals",
      instagram: "glossolaliarecords",
    },
  ],
  navItems: [
    {
      name: `The Memory of Death Given Form (Vinyl)`,
      link: "https://paraphilia.bandcamp.com/album/the-memory-of-death-given-form",
      icon: faBandcamp,
      active: true,
      desktop: true,
      mobile: true,
      key: 9,
    },
    {
      name: "Guitar Tabs",
      link: "/guitar-tabs",
      icon: faCompactDisc,
      active: false,
      desktop: false,
      mobile: true,
      key: 1234,
    },
    {
      name: "Coruscation (Playthrough)",
      link: "https://www.youtube.com/watch?v=hWEk55r2EgY",
      icon: faYoutube,
      active: true,
      desktop: true,
      mobile: true,
      key: 123,
    },
    {
      name: "The Memory of Death Given Form",
      link: "https://open.spotify.com/album/72kdwlKBT8JHmm5u0S5u1z?si=GcpeOEUBQ1W99OSeWSUzyQ",
      icon: faSpotify,
      active: false,
      desktop: true,
      mobile: true,
      key: 5,
    },
    {
      name: "EPK",
      link: "https://www.sonicbids.com/band/paraphilia/",
      icon: faCompactDisc,
      active: false,
      desktop: false,
      mobile: true,
      key: 6,
    },
    {
      name: "Instagram",
      link: "https://instagram.com/paraphilia.death",
      icon: faInstagram,
      active: true,
      desktop: true,
      mobile: true,
      key: 2,
    },
    {
      name: "Facebook",
      link: "https://www.facebook.com/paraphilia.band",
      icon: faFacebook,
      active: true,
      desktop: false,
      mobile: true,
      key: 8,
    },
    {
      name: "Contact",
      link: "mailto:paraphilia.pnw@gmail.com",
      icon: faEnvelope,
      active: true,
      mobile: true,
      desktop: true,
      key: 4,
    },
  ],
};

export default band;
