import React, { ReactElement } from "react";
import paraphilia from "./components/data/Band";

import { BrowserRouter } from "react-router-dom";

import "./styles/styles.css";

// Component
import Main from "./components/Main/Main";
import Header from "./components/Header/Header";
import HelmetData from "./components/HelmetData/HelmetData";

const App = (): ReactElement => {
  const { logo } = paraphilia;

  return (
    <div className="App">
      <BrowserRouter>
        <HelmetData />
        <Header logo={logo} />
        <Main band={paraphilia} />
      </BrowserRouter>
    </div>
  );
};

export default App;
